<template>
    <div class="padding24 color_white" id="third_Party_order">
        <a-spin :spinning="spinning" size="large" :tip="tip">
            <div class="isDisplay" v-show="$route.meta.isShow">
                <div class="top">
                    <a-form layout="inline">
                        <a-form-item>
                            <a-button icon="plus" class="margin_right20" type="primary" @click="addClick">添加</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <!-- 表格 -->
                <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"  :loading="tableLoading">
                    <img v-viewer class="avatar" slot="coverUrl" slot-scope="coverUrl, record" :src="record.coverUrl" alt="封面图"/>
                    <span slot="putawayFlag" slot-scope="putawayFlag, record">
                        <a-badge v-if="record.putawayFlag == 1" status="success" text="已上架"/>
                        <a-badge v-else status="warning" text="已下架"/>
                    </span>
                </a-table>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
                <!-- 返回顶部 -->
                <a-back-top :target="targetFn" :visibilityHeight="100"/>
            </div>
        </a-spin>
        <router-view />
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {VideoList} from "@/request/api/appletsManage";
export default {
    components: {MyPagination},
    created() {
        this.getInitFn();
    },
    data() {
        return {
            isShowAddOrEdit:false,
            isShowStatus:false,

            tip: "",
            tableLoading: true,
            spinning: false, // 全局loading
            pageNo: 1,
            pageSize: 20,
            count: 0,

            columns: [
                {
                    title: "名称",
                    dataIndex: "title",
                    key: "title",
                },
                {
                    title: "封面图",
                    dataIndex: "coverUrl",
                    key: "coverUrl",
                    scopedSlots: {customRender: "coverUrl"},
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                },
                {
                    title: "下架时间",
                    dataIndex: "downawayTime",
                    key: "downawayTime",
                },
                {
                    title: "状态",
                    dataIndex: "putawayFlag",
                    key: "putawayFlag",
                    scopedSlots: {customRender: "putawayFlag"},
                },
            ],
            tableData: [],
        };
    },

    watch: {
        $route(to) {
            if (to.path === "/appletsManage/liveBroadcast") {
                this.getInitFn();
            }
        },
    },

    methods: {
        // 点击添加按钮
        addClick() {
            this.$router.push("/appletsManage/liveBroadcastEidt/0")
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getInitFn();
        },

        // 原属数据获取
        getInitFn() {
            VideoList({
                pageSize:this.pageSize,
                pageNo:this.pageNo
            }).then(({ code, data }) => {
                this.tableLoading = false
                this.tableData = data.list;
                this.count = data.count
            });
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#third_Party_order");
        },
    },
};
</script>

<style lang="less" scoped>
.avatar{
    width: 50px;
}

#third_Party_order {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.top {
    .top_line {
        margin-bottom: 20px;
        display: flex;

        span {
            margin-right: 20px;

            /deep/ .ant-input {
                width: 220px;
            }

            /deep/ .ant-select-selection {
                width: 110px;
                margin-left: 10px;
            }

            /deep/ .ant-calendar-picker-input {
                margin-left: 10px;
                width: auto;
            }

            /deep/ .ant-calendar-picker {
                width: 400px !important;
            }
        }

        .ant-btn {
            margin-right: 20px;
        }
    }
}
</style>
